<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
  <!-- EOC -->
  <!-- BOC:[header] -->
  <v-row class="mb-3">
    <v-col><h1>{{$t("model.name.teachers")}}</h1></v-col>
  </v-row>
  <!-- EOC -->
  <!-- BOC:[table] -->
  <BreadBrowseTable
    role="Moderator"
    :model="model"
    :url="`${$api.servers.sso}/api/v1/en/moderator/teacher`"
    :isSearchable="true"
  ></BreadBrowseTable>
  <!-- EOC -->
</v-container>
</template>

<script>
  //BOC:[model]
  import model from '@/models/items/teacher'
  //EOC
  //BOC:[table]
  import BreadBrowseTable from '@/components/Bread/BreadBrowseTableV2'
  //EOC
  import { mapState } from 'vuex'
  export default {
    components:{
      //BOC:[table]
      BreadBrowseTable,
      //EOC
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      //
    ],
    data:()=>({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[model]
      model: model,
      //EOC
      //BOC:[role]
      role:'Moderator',
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.model.name.plural.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.model.key),
        exact:true,
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>